import React, { Component } from "react";
import store from "../../../redux/store";
import { Provider } from "react-redux";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import AddFloorPlanPictureForm from "./AddFloorPlanPictureForm";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
class AddFloorPlan extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      rvtype: "",
      manufacturer: "",
      brand: "",
      model: "",
      color: "",
      submitted: false,
      year: "",
      floorplanName: null,
      errors: null,
      dropDownValuesRVAddInventorya: !this.props.manufactures
        ? null
        : {
            data: [
              this.props.brands,
              this.props.colors,
              this.props.manufactures,
              this.props.models,
              this.props.types
            ]
          }
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    let res = await axios.get("/api/rv-inventory-get-dropdowns/");
    this.setState({ dropDownValuesRVAddInventorya: { data: res.data } });
  }

  handleSubmit(e) {
    e.preventDefault(); // prevent native submitting behavior
    let errors = [];
    if (!this.state.rvtype) {
      errors.push("Type");
    }
    if (!this.state.manufacturer) {
      errors.push("Manufacturer");
    }
    if (!this.state.brand) {
      errors.push("Brand");
    }
    if (!this.state.model) {
      errors.push("Model");
    }
    if (!this.state.color) {
      errors.push("Color");
    }
    if (errors === undefined || errors.length === 0) {
      this.setState({
        submitted: true,
        floorplanName:
          this.state.year +
          " " +
          this.state.brand +
          " " +
          this.state.manufacturer +
          " " +
          this.state.model +
          " " +
          this.state.color +
          " " +
          this.state.rvtype
      });
    } else {
      this.setState({ errors: errors });
    }

    // do anything on submit, here!
  }
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  renderErrors() {
    if (this.state.errors) {
      return (
        <Alert variant="danger">
          <p>Please fix the below errors:</p>
          <ul>
            {this.state.errors.map((text, idx) => (
              <li key={idx}>{text}</li>
            ))}
          </ul>
        </Alert>
      );
    }
  }
  renderContent() {
    switch (this.state.dropDownValuesRVAddInventorya) {
      case null:
        return <p>loading...</p>;
      case false:
        return <p>loading...</p>;
      case "Error":
        return <p>Error getting values, please refresh page!</p>;
      default:
        if (!this.state.submitted) {
          return (
            <div>
              {this.renderErrors()}
              <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="fromYears">
                  <Form.Label>Enter a year</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter a Year"
                    name="year"
                    value={this.state.year}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="fromType">
                  <Form.Label>Select a type</Form.Label>
                  <FormControl
                    as="select"
                    name="rvtype"
                    value={this.state.rvtype}
                    onChange={this.handleInputChange}
                  >
                    <option value=" " key={0}></option>
                    {this.state.dropDownValuesRVAddInventorya.data[4].map(
                      rows => (
                        <option value={rows.value} key={rows.key}>
                          {rows.value}
                        </option>
                      )
                    )}
                  </FormControl>
                </Form.Group>

                <Form.Group controlId="formManufacturer">
                  <Form.Label>Select a Manufacturer</Form.Label>
                  <FormControl
                    as="select"
                    name="manufacturer"
                    value={this.state.manufacturer}
                    onChange={this.handleInputChange}
                  >
                    <option value=" " key={0}></option>
                    {this.state.dropDownValuesRVAddInventorya.data[2].map(
                      rows => (
                        <option value={rows.value} key={rows.key}>
                          {rows.value}
                        </option>
                      )
                    )}
                  </FormControl>
                </Form.Group>
                <Form.Group controlId="formBrand">
                  <Form.Label>Select a Brand</Form.Label>
                  <FormControl
                    as="select"
                    name="brand"
                    value={this.state.brand}
                    onChange={this.handleInputChange}
                  >
                    <option value=" " key={0}></option>
                    {this.state.dropDownValuesRVAddInventorya.data[0].map(
                      rows => (
                        <option value={rows.value} key={rows.key}>
                          {rows.value}
                        </option>
                      )
                    )}
                  </FormControl>
                </Form.Group>
                <Form.Group controlId="fromModel">
                  <Form.Label>Select a Model</Form.Label>
                  <FormControl
                    as="select"
                    name="model"
                    value={this.state.model}
                    onChange={this.handleInputChange}
                  >
                    <option value=" " key={0}></option>
                    {this.state.dropDownValuesRVAddInventorya.data[3].map(
                      rows => (
                        <option value={rows.value} key={rows.key}>
                          {rows.value}
                        </option>
                      )
                    )}
                  </FormControl>
                </Form.Group>
                <Form.Group controlId="fromColor">
                  <Form.Label>Select a Color</Form.Label>
                  <FormControl
                    as="select"
                    name="color"
                    value={this.state.color}
                    onChange={this.handleInputChange}
                  >
                    <option value=" " key={0}></option>
                    {this.state.dropDownValuesRVAddInventorya.data[1].map(
                      rows => (
                        <option value={rows.value} key={rows.key}>
                          {rows.value}
                        </option>
                      )
                    )}
                  </FormControl>
                </Form.Group>
                <Button type="submit">Click To Add Floorplan Picture</Button>
              </Form>
            </div>
          );
        } else {
          return (
            <AddFloorPlanPictureForm
              floorplanName={this.state.floorplanName}
              data={this.props.data}
            />
          );
        }
    }
  }

  renderForm() {
    return (
      <Provider store={store}>
        <div style={{ padding: 15 }}>
          <h2 className="text-center">Add Floorplan</h2>
          {this.renderContent()}
          <Button>Click Here To Add Floorplan Picture</Button>
        </div>
      </Provider>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}
function mapStateToProps({ dropDownValuesRVAddInventory }) {
  return { dropDownValuesRVAddInventory };
}
export default connect(mapStateToProps, actions)(AddFloorPlan);
