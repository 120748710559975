import React, { Component } from 'react';

class SubMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false
    };
    this.showMenu = this.showMenu.bind(this);
  }
  componentDidMount() {

  }
  showMenu(event) {
    event.preventDefault();
    this.setState(function (state) {
      return {
        showMenu: !state.showMenu
      };
    });
  }

  render() {
    return (
      <div
        className="bm-item btn-floating btn-large red"
        onClick={this.showMenu}
      >
        {this.props.name}
        {this.state.showMenu ? (
          <i className="fa fa-caret-down" />
        ) : (
            <i className="fa fa-caret-left" />
          )}
        {this.state.showMenu ? (
          <div className="btn-floating btn-large red">
            {this.props.children}
          </div>
        ) : null}

      </div>
    );
  }
}

export default SubMenu;
