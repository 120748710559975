import React from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./../validate";
import { renderSelectAddNew, renderField } from "../renderField";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddSingleRVOption from "./../AddSingleRVOption";
const MainInfoForm = props => {
  const {
    handleSubmit,
    manufactures,
    brands,
    colors,
    models,
    types,
    setupMainInfoForAdding
  } = props;
  const [show, setShow] = React.useState(false);
  const [addWhat, setAddWhat] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showAddNewPopup = e => {
    if (e.target.name !== "TheYear") {
      let index = e.nativeEvent.target.selectedIndex;
      let textValue = e.nativeEvent.target[index].text;
      setupMainInfoForAdding(e.target.name, textValue);
      if (e.target.value === "0") {
        setAddWhat(e.target.name);
        handleShow();
      }
    } else {
      setupMainInfoForAdding(e.target.name, e.target.value);
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <AddSingleRVOption
            addWhat={addWhat}
            handleClose={handleClose}
            data={{
              manufactures: manufactures,
              brands: brands,
              colors: colors,
              models: models,
              types: types
            }}
          ></AddSingleRVOption>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="formYears">
          <Form.Label>Enter a year</Form.Label>
          <Field
            type="select"
            placeholder="Enter a Year"
            label="year"
            name="TheYear"
            maxlength="4"
            component={renderField}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <Form.Group controlId="formType">
          <Form.Label>Select a type</Form.Label>
          <Field
            type="select"
            placeholder="Select a type"
            label="type"
            name="TypeID"
            options={types}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />{" "}
        </Form.Group>

        <Form.Group controlId="formManufacturer">
          <Form.Label>Select a Manufacturer</Form.Label>
          <Field
            type="select"
            placeholder="Select a Manufacturer"
            label="manufacturer"
            name="ManufacturerID"
            options={manufactures}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <Form.Group controlId="formBrand">
          <Form.Label>Select a brand</Form.Label>
          <Field
            type="select"
            placeholder="Select a Brand"
            label="brand"
            name="BrandID"
            options={brands}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <Form.Group controlId="formModel">
          <Form.Label>Select a Model</Form.Label>
          <Field
            type="select"
            placeholder="Select a Model"
            label="model"
            name="ModelID"
            options={models}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <Form.Group controlId="formColor">
          <Form.Label>Select a Color</Form.Label>
          <Field
            type="select"
            placeholder="Select a color"
            label="color"
            name="ColorID"
            options={colors}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <div>
          <Button className="pull-right next" variant="success" type="submit">
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "wizard", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(MainInfoForm);
