import React from 'react';
import Table from './Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

const styles = {
  col: {
    paddingLeft: 5,
    paddingRight: 25,
  },
};
function isClearance(isClearance, StockNumber, updateClearance) {
  if (isClearance === 'Yes') {
    return (
      <Col xs={4} style={styles.col}>
        <Button
          variant="link"
          onClick={() => updateClearance(StockNumber, false)}
        >
          <img
            src="https://static231media.com/public/images/231portal/icons/clearance.png"
            alt="Clearance"
            title="Mark Clearance"
            width="16"
            height="16"
          />
        </Button>
      </Col>
    );
  } else {
    return (
      <Col xs={4} style={styles.col}>
        <Button
          variant="link"
          onClick={() => updateClearance(StockNumber, true)}
        >
          <img
            src="https://static231media.com/public/images/231portal/icons/not-clearance.png"
            alt="Not Sold"
            title="Mark Not Clearance"
            width="16"
            height="16"
          />
        </Button>
      </Col>
    );
  }
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      as="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        aria-describedby="inputGroup-sizing-sm"
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        aria-describedby="inputGroup-sizing-sm"
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number';

function InventoryList({
  rvUnits,
  editRVItem,
  addPictures,
  organizePictures,
  deleteRVItemService,
  updateClearance,
  updateSold,
  soldView,
}) {
  const columns = [
    {
      Header: ' ',
      isVisible: false,
      id: 'units',
      hideHeader: false,
      columns: [
        {
          Header: 'Unit ID',
          accessor: 'UnitID',
          disableFilters: true,
        },
        {
          Header: 'Stock Number',
          accessor: 'StockNumber',
          // Use our custom `fuzzyText` filter on this column
          filter: 'fuzzyText',
        },
        {
          Header: 'Description',
          accessor: 'Description',
          // Filter: NumberRangeColumnFilter,
          // filter: "between"
        },
        {
          Header: 'Type',
          accessor: 'Type',
          Filter: SelectColumnFilter,
          filter: 'includes',
        },
        {
          Header: 'Color',
          accessor: 'Color',
          Filter: SelectColumnFilter,
          filter: 'includes',
        },
        {
          Header: 'Pics',
          accessor: 'Pics',
          sortDescFirst: true,
          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Days Listed',
          accessor: 'DaysListed',
          sortDescFirst: true,

          Filter: NumberRangeColumnFilter,
          filter: 'between',
        },
        {
          Header: 'Clearance',
          accessor: 'Clearance',
          Filter: SelectColumnFilter,
          filter: 'includes',
        },
        ...(!soldView
          ? [
              {
                Header: 'Actions',
                accessor: 'actions',
                disableFilters: true,

                Cell: ({ cell }) => (
                  <div>
                    <Row>
                      <Col xs={4} style={styles.col}>
                        <Button
                          variant="link"
                          onClick={() =>
                            editRVItem(cell.row.values.StockNumber)
                          }
                        >
                          <img
                            src="https://static231media.com/public/images/231portal/icons/edit.png"
                            alt="Edit"
                            title="Edit"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </Col>
                      <Col xs={4} style={styles.col}>
                        <Button
                          variant="link"
                          onClick={() => addPictures(cell.row.values.UnitID)}
                        >
                          <img
                            src="https://static231media.com/public/images/231portal/icons/add-pictures.png"
                            alt="Pictures"
                            title="Pictures"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </Col>
                      <Col xs={4} style={styles.col}>
                        <Button
                          variant="link"
                          onClick={() =>
                            organizePictures(cell.row.values.UnitID)
                          }
                        >
                          <img
                            src="https://static231media.com/public/images/231portal/icons/organize-pictures.png"
                            alt="OrganizePictures"
                            title="OrganizePictures"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </Col>
                      {!soldView ? (
                        <Col xs={4} style={styles.col}>
                          <Button
                            variant="link"
                            onClick={() =>
                              updateSold(
                                cell.row.values.UnitID,
                                cell.row.values.StockNumber
                              )
                            }
                          >
                            <img
                              src="https://static231media.com/public/images/231portal/icons/money-bag.png"
                              alt="MarkUnitSold"
                              title="MarkUnitSold"
                              width="16"
                              height="16"
                            />
                          </Button>
                        </Col>
                      ) : (
                        <></>
                      )}
                      {isClearance(
                        cell.row.values.Clearance,
                        cell.row.values.StockNumber,
                        updateClearance
                      )}
                      <Col xs={4} style={styles.col}>
                        <Button
                          variant="link"
                          onClick={() =>
                            deleteRVItemService(
                              cell.row.values.UnitID,
                              cell.row.values.StockNumber
                            )
                          }
                        >
                          <img
                            src="https://static231media.com/public/images/231portal/icons/delete.png"
                            alt="Delete"
                            title="Delete"
                            width="16"
                            height="16"
                          />
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ),
              },
            ]
          : [
              {
                Header: 'Actions',
                accessor: 'actions',
                disableFilters: true,

                Cell: ({ cell }) => (
                  <div>
                    <Row>
                      <Col>No actions</Col>
                    </Row>
                  </div>
                ),
              },
            ]),
      ],
    },
  ];
  if (!rvUnits) {
    return <div>Loading..</div>;
  } else if (rvUnits.length === 0) {
    return <div>No Units! Click Add Unit in the Sidebar to add one!</div>;
  } else {
    return <Table columns={columns} data={rvUnits} soldView={soldView} />;
  }
}

export default InventoryList;
