export const FETCH_USER = "FETCH_USER";
export const FETCH_CREDIT_APPS = "FETCH_CREDIT_APPS";
export const DELETE_CREDIT_APP = "DELETE_CREDIT_APP";
export const DELETE_CREDIT_APP_ERROR = "DELETE_CREDIT_APP_ERROR";
export const DROPDOWN_VALUE_ADD_INVENTORY = "DROPDOWN_VALUE_ADD_INVENTORY";
export const DROPDOWN_VALUE_ADD_INVENTORY_ERROR =
  "DROPDOWN_VALUE_ADD_INVENTORY_ERROR";
export const FETCH_RV_INVENTORY_UNITS = "FETCH_RV_INVENTORY_UNITS";
export const GET_RV_ERROR = "GET_RV_ERROR";
export const GET_RV_UNIT = "GET_RV_UNIT";
export const ADD_RV_UNIT = "ADD_RV_UNIT";
export const ADD_RV_UNIT_ERROR = "ADD_RV_UNIT_ERROR";
export const INSERT_RV_OPTION = "INSERT_RV_OPTION";
export const INSERT_RV_OPTION_ERROR = "INSERT_RV_OPTION";
export const GET_RV_PICTURES_FOR_ORDER = "GET_RV_PICTURES_FOR_ORDER";
export const GET_RV_PICTURES_FOR_ORDER_ERROR =
  "GET_RV_PICTURES_FOR_ORDER_ERROR";
