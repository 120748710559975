import React, { Component } from "react";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
class AddSingleRVOption extends Component {
  renderContent() {
    if (this.state.rvOptionsInsertResults === "Success") {
      return <Alert variant="success">Success!</Alert>;
    } else if (this.state.rvOptionsInsertResults) {
      return (
        <Alert variant="danger">{this.state.rvOptionsInsertResults}</Alert>
      );
    }
  }

  constructor(props) {
    super(props);
    this.addWhat = this.props.addWhat;
    this.state = { optionValue: "", rvOptionsInsertResults: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    this.setState({ optionValue: event.target.value });
  }
  async handleSubmit(event) {
    await event.preventDefault();
    let res;
    try {
      res = await axios.post("/api/insert-rv-option/", {
        optionValue: this.state.optionValue,
        optionType: this.addWhat,
      });
      if (res.data.msg === "Success") {
        this.setState({ rvOptionsInsertResults: "Success" });
        switch (this.addWhat) {
          case "TypeID":
            this.props.data.types.unshift({
              key: res.data.data[0][0]["ID"],
              value: this.state.optionValue,
            });
            break;
          case "BrandID":
            this.props.data.brands.unshift({
              key: res.data.data[0][0]["ID"],
              value: this.state.optionValue,
            });
            break;
          case "ModelID":
            this.props.data.models.unshift({
              key: res.data.data[0][0]["ID"],
              value: this.state.optionValue,
            });
            break;
          case "ColorID":
            this.props.data.colors.unshift({
              key: res.data.data[0][0]["ID"],
              value: this.state.optionValue,
            });
            break;
          case "ManufacturerID":
            this.props.data.manufactures.unshift({
              key: res.data.data[0][0]["ID"],
              value: this.state.optionValue,
            });
            break;
          default:
        }
        this.props.handleClose();
      } else {
        this.setState({ rvOptionsInsertResults: JSON.stringify(res.data.msg) });
      }
    } catch (e) {
      this.setState({
        rvOptionsInsertResults:
          "Error, Please contact admin: " + JSON.stringify(e),
      });
    }
  }
  render() {
    return (
      <div>
        {this.renderContent()}
        <div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group controlId="form.OptionValue">
              <Form.Label>Value:</Form.Label>
              <Form.Control
                type="text"
                placeholder="value"
                name="optionValue"
                value={this.state.optionValue}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(AddSingleRVOption);

function mapStateToProps({ rvOptionsInsertResults }) {
  return { rvOptionsInsertResults };
}
