import React, { Component } from 'react';

class Welcome extends Component {
  render() {
    return (
      <div className="Welcome">
        <p>Welcome</p>
      </div>
    );
  }
}

export default Welcome;
