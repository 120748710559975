import React, { Component } from "react";
import { connect } from "react-redux";
// import { Route } from "react-router-dom";

import Login from "./Login";
import Home from "./Home";

class Portal extends Component {
  constructor(props) {
    super(props);
    this.state = { logout: null };
    this.logoutPortal = this.logoutPortal.bind(this);
  }
  logoutPortal() {
    this.setState({ logout: true });
  }
  renderContent() {
    switch (this.props.auth) {
      case null:
        return <Login />;
      case false:
        return (
          <div>
            <Login success="false" />
          </div>
        );

      default:
        // if (this.props.auth.data.wordpress) {
        // return (
        //   <Route
        //     path="/"
        //     component={() => {
        //       window.location = '/api/wordpressLogin';
        //       return null;
        //     }}
        //   />
        // );
        ///  return (
        // <div>
        // <Home />
        //  </div>
        //  );
        // } else {
        switch (this.state.logout) {
          case true:
            return <Login />;
          default:
            return (
              <div>
                <Home logoutPortal={this.logoutPortal} />
              </div>
            );
        }
      //    }
    }
  }

  render() {
    return <div> {this.renderContent()} </div>;
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}
export default connect(mapStateToProps)(Portal);
