import React, { Component } from 'react';

class Contact extends Component {
  renderContent() {}

  render() {
    return (
      <div>
        <p>Contact</p>
      </div>
    );
  }
}

export default Contact;
