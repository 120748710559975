import React from "react";
import DropzoneComponent from "react-dropzone-component";

const AddFloorPlanPictureForm = props => {
  const { floorplanName } = props;
  var componentConfig = {
    iconFiletypes: [".jpg", ".png", ".gif"],
    showFiletypeIcon: true,
    postUrl: "/api/upload/"
  };
  var djsConfig = {
    addRemoveLinks: false,
    acceptedFiles: "image/*",
    maxFiles: 1,
    params: {
      type: "floorPlan",
      floorplanName: floorplanName
    }
  };
  var eventHandlers = {
    success: function (file, response) {
      if (props.floorplans) {
        if (response.status === "success") {
          props.floorplans.push({
            key: response.msg,
            value: floorplanName
          });
        } else {
          props.setError(true);
        }
      }
    }
  };
  return (
    <div>
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    </div>
  );
};

export default AddFloorPlanPictureForm;
