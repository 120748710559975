import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import validate from "./../validate";
import { renderField, renderCheckBox } from "./../renderField";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

class PricingForm extends Component {
  componentDidMount() {}
  renderContent() {
    return this.renderForm();
  }
  renderForm() {
    return (
      <div>
        <h2>Specifications</h2>
        <form onSubmit={this.props.handleSubmit}>
          <Form.Group controlId="msrp">
            <Field
              type="text"
              placeholder="MSRP"
              label="msrp"
              name="MSRP"
              component={renderField}
            />
          </Form.Group>

          <Form.Group controlId="salePrice">
            <Field
              type="text"
              placeholder="Sale Price"
              label="salePrice"
              name="SalePrice"
              component={renderField}
            />
          </Form.Group>

          <Form.Group controlId="New">
            <Field
              type="checkbox"
              label="New"
              name="NewUsed"
              component={renderCheckBox}
            />
          </Form.Group>
          <Form.Group controlId="Clearance">
            <Field
              type="checkbox"
              label="Clearance"
              name="Clearance"
              component={renderCheckBox}
            />
          </Form.Group>
          <div>
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button className="pull-right next" variant="success" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

PricingForm.defaultProps = {
  rvUnit: [],
};
export default reduxForm({
  form: "wizard",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(PricingForm);
