import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { Redirect, withRouter } from "react-router-dom";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import axios from "axios";
const arrayMove = require("array-move");

const gridStyles = {
  display: "grid",
  maxWidth: "100rem",
  margin: "auto",
  padding: "2rem",
  gridTemplateColumns: "repeat( auto-fit, minmax(250px, 1fr) )",
  //gridTemplateColumns: 'repeat(6, 1fr)',
  gridGap: "10px",
};

const gridItemStyles = {
  height: "250px",
  width: "250px",
  backgroundColor: "#e5e5e5",
};

const contextMenu = async (
  e,
  unitID,
  pictureID,
  index,
  removeACard,
  displayDeleteError
) => {
  e.preventDefault();

  //confirm Delete
  const r = window.confirm("Do you really want to delete this Picture?");
  if (r === true) {
    //call delete function
    try {
      let res = await axios.post("/api/delete-rv-picture", {
        UnitID: unitID,
        PictureID: pictureID,
      });

      if (res.data.status === "Success") {
        await removeACard(index);
      } else {
        await displayDeleteError(
          `Error Deleting Unit: ${pictureID}. Please refresh page and try again, if the issue persists, please contact an admin.`
        );
      }
    } catch {
      displayDeleteError(
        "Error Deleting Unit: " +
          pictureID +
          ". Please refresh page and try again, if the issue persists, please contact an admin."
      );
    }
    //on success remove from cards or should we call get cards again?
    //on failure die///api/delete-rv-picture",
  }
};
const GridItem = SortableElement(({ value }) => (
  <div style={gridItemStyles}>
    <img alt="VariousUnitPictures" src={value} width="250" height="250" />
  </div>
));

const Grid = SortableContainer(
  ({ cards, unitID, removeACard, displayDeleteError }) => (
    <div style={gridStyles}>
      {cards.map((value, index) => (
        <div
          id={index}
          key={value.PictureID}
          onContextMenu={(e) =>
            contextMenu(
              e,
              unitID,
              value.PictureID,
              index,
              removeACard,
              displayDeleteError
            )
          }
        >
          <GridItem
            key={value.PictureID}
            index={index}
            id={index}
            value={value.PictureLink}
          />
        </div>
      ))}
    </div>
  )
);

class RVPictureOrganizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateStatus: "",
      updateMessage: "",
      show: false,
      redirect: false,
      cards: [],
    };
    this.hideModal = this.hideModal.bind(this);
    this.removeACard = this.removeACard.bind(this);
    this.displayDeleteError = this.displayDeleteError.bind(this);
  }

  async componentDidMount() {
    // this.props.getRVPicturesForOrder();
    let res = await axios.post("/api/rv-get-pics-by-id", {
      unitID: this.props.id,
    });
    this.setState({
      unitID: this.props.id,
      cards: res.data,
    });
  }
  async removeACard(index) {
    this.setState((prevState) => {
      let newCards = [...prevState.cards];
      //delete unit from array
      newCards.splice(index, 1);
      return { cards: newCards };
    });

    let res;
    res = await axios.post("/api/rv-update-pics-order-by-id", {
      cards: this.state.cards,
    });
    if (res.data.status !== "updated") {
      this.setState({
        show: true,
        updateStatus: res.data.status,
        updateMessage: res.data.msg,
      });
    }
  }
  async displayDeleteError(error) {
    this.setState({
      show: true,
      updateStatus: "error",
      updateMessage: error,
    });
  }
  async updatePictureOrder(values) {
    let res;
    res = await axios.post("/api/rv-update-pics-order-by-id", {
      cards: values,
    });
    if (res.data.status === "updated") {
      if (this.props.viewInventory) {
        this.props.viewInventory();
      } else {
        this.setState({
          redirect: true,
        });
      }
    } else {
      this.setState({
        show: true,
        updateStatus: "error",
        updateMessage: res.data.msg,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({
      cards: arrayMove(this.state.cards, oldIndex, newIndex),
    });
  };

  renderOrganizer() {
    return (
      <div>
        <Grid
          cards={this.state.cards}
          unitID={this.state.unitID}
          onSortEnd={this.onSortEnd}
          removeACard={this.removeACard}
          displayDeleteError={this.displayDeleteError}
          axis="xy"
        />

        <Button
          className="pull-right next"
          variant="success"
          //  onClick={this.props.viewInventory}
          onClick={() => this.updatePictureOrder(this.state.cards)}
          //onClick={() => this.props.updateRVPictureOrder(this.state.cards)}
        >
          Submit
        </Button>
      </div>
    );
  }
  hideModal() {
    this.setState({ show: false });
  }
  renderContent() {
    if (this.state.updateStatus === "error") {
      return (
        <div>
          <Modal show={this.state.show} onHide={this.hideModal}>
            <Modal.Body>
              <p>{this.state.updateMessage}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {this.renderOrganizer()};
        </div>
      );
    } else if (this.state.redirect) {
      return <Redirect to="/view-rv-inventory" />;
    } else {
      return this.renderOrganizer();
    }
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps({ rvGetPicturesForOrganizerResults }) {
  return { rvGetPicturesForOrganizerResults };
}
export default withRouter(connect(mapStateToProps)(RVPictureOrganizer));
