import React from 'react';
import DropzoneComponent from 'react-dropzone-component';

const AddBrochurePictureForm = (props) => {
  const { brochureName } = props;
  var componentConfig = {
    iconFiletypes: ['.jpg', '.png', '.gif', '.pdf'],
    showFiletypeIcon: true,
    postUrl: '/api/upload/',
  };
  var djsConfig = {
    addRemoveLinks: false,
    acceptedFiles: 'image/*,application/pdf',
    maxFiles: 1,
    params: {
      type: 'brochure',
      brochureName: brochureName,
    },
  };
  var eventHandlers = {
    success: function (file, response) {
      if (props.brochures) {
        if (response.status === 'success') {
          props.brochures.push({
            key: response.msg,
            value: brochureName,
          });
        } else {
          props.setError(true);
        }
      }
    },
  };
  return (
    <div>
      <DropzoneComponent
        config={componentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    </div>
  );
};

export default AddBrochurePictureForm;
