import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { BrowserRouter } from 'react-router-dom';
import Portal from './Portal';

//import './sidebar/styles.css';
class App extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <BrowserRouter>
        <div id="App">
          <Portal />
        </div>
      </BrowserRouter>
    );
  }
}
export default connect(null, actions)(App);
