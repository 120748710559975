import { ADD_RV_UNIT, ADD_RV_UNIT_ERROR } from "../actions/types";

export default function addRVInventorySubmitReducer(state = null, action) {
  switch (action.type) {
    case ADD_RV_UNIT:
      return action.payload || false;
    case ADD_RV_UNIT_ERROR:
      return action.payload || false;
    default:
      return state;
  }
}
