import axios from "axios";
import {
  FETCH_USER,
  FETCH_CREDIT_APPS,
  DELETE_CREDIT_APP,
  DELETE_CREDIT_APP_ERROR,
  DROPDOWN_VALUE_ADD_INVENTORY,
  DROPDOWN_VALUE_ADD_INVENTORY_ERROR,
  ADD_RV_UNIT,
  ADD_RV_UNIT_ERROR,
  GET_RV_ERROR,
  GET_RV_UNIT,
  INSERT_RV_OPTION,
  INSERT_RV_OPTION_ERROR,
  GET_RV_PICTURES_FOR_ORDER,
  GET_RV_PICTURES_FOR_ORDER_ERROR,
} from "./types";
// export const fetchUser = () => {
//   return function(dispatch) {
//     axios
//       .get('/api/current-user')
//       .then(res => dispatch({ type: FETCH_USER, payload: res }));
//   };
// };

export const fetchUser = () => async (dispatch) => {
  //const res = await axios.get("/api/current-user");

  var data = null;
  try {
    //  let res = await axios.post("/api/login", values,validateStatus: function(status) {return true;}));
    let res = await axios({
      method: "get",
      url: "/api/current-user",
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    });

    if (res.status === 200) {
      data = res.data;
    } else if (res.status === 401) {
      data = null;
    } else {
      data = null;
    }
  } catch {
    data = null;
  }

  // var data = null;
  // try {
  //   if (res.status === 200) {
  //     data = res.data;
  //   } else {
  //     data = false;
  //   }
  // } catch {
  //   data = false;
  // }

  dispatch({ type: FETCH_USER, payload: data });
};

export const submitLogin = (values) => async (dispatch) => {
  var data = null;
  try {
    //  let res = await axios.post("/api/login", values,validateStatus: function(status) {return true;}));
    let res = await axios({
      method: "post",
      url: "/api/login",
      data: values,
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
    });

    if (res.status === 200) {
      data = res.data;
    } else if (res.status === 401) {
      data = false;
    } else {
      data = false;
    }
  } catch {
    data = false;
  }

  dispatch({ type: FETCH_USER, payload: data });
};

export const getCreditApps = () => async (dispatch) => {
  const res = await axios.get("/api/viewCreditApps");

  dispatch({ type: FETCH_CREDIT_APPS, payload: res });
};

export const deleteCreditApp = (values) => async (dispatch) => {
  try {
    await axios.post("/api/deleteCreditApp", values);
    dispatch({ type: DELETE_CREDIT_APP, payload: values });
  } catch (e) {
    dispatch({ type: DELETE_CREDIT_APP_ERROR, payload: values });
  }
};

export const getDropDownValuesRVAddInventory = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/rv-inventory-get-dropdowns/");
    dispatch({ type: DROPDOWN_VALUE_ADD_INVENTORY, payload: res });
  } catch (e) {
    dispatch({ type: DROPDOWN_VALUE_ADD_INVENTORY_ERROR, payload: "error" });
  }
};

export const getRVUnit = (stockNumber) => async (dispatch) => {
  try {
    const res = await axios.post("/api/rv-inventory-item-no-pics/", {
      stockNumber: stockNumber,
    });
    dispatch({ type: GET_RV_UNIT, payload: res });
  } catch (e) {
    dispatch({ type: GET_RV_ERROR, payload: "error" });
  }
};

export const showResults = async (values, dispatch) => {
  try {
    let res = await axios.post("/api/rv-inventory-add-inventory/", values);
    if (res.data.status === "success") {
      dispatch({ type: ADD_RV_UNIT, payload: res.data });
    } else {
      dispatch({ type: ADD_RV_UNIT_ERROR, payload: res.data });
    }
  } catch (e) {
    dispatch({
      type: ADD_RV_UNIT_ERROR,
      payload: "Error: Please contact admin. " + JSON.stringify(e),
    });
  }
};

export const updateRVUnit = async (values, dispatch) => {
  try {
    let res = await axios.post("/api/update-unit/", values);
    if (res.data.status === "updated") {
      dispatch({ type: ADD_RV_UNIT, payload: res.data });
    } else {
      dispatch({ type: ADD_RV_UNIT_ERROR, payload: res.data });
    }
  } catch (e) {
    dispatch({
      type: ADD_RV_UNIT_ERROR,
      payload: "error" + JSON.stringify(e),
    });
  }
};

export const insertRVOption = async (values, dispatch) => {
  let res;
  try {
    res = await axios.post("/api/insert-rv-option/", values);
    if (res.data.msg === "Success") {
      dispatch({ type: INSERT_RV_OPTION, payload: "Success" });
    } else {
      dispatch({ type: INSERT_RV_OPTION_ERROR, payload: res.data.msg });
    }
  } catch (e) {
    dispatch({
      type: INSERT_RV_OPTION_ERROR,
      payload: "Error: Could not connect to server!",
    });
  }
};

// export const updateRVPictureOrder = async values => {
//   let res;
//   try {
//     res = await axios.post("/api/rv-update-pics-order-by-id", {
//       cards: values
//     });
//     // if (res.data === "Success") {
//     //   alert("Successfully Updated!");
//     // } else {
//     //   // alert(res.data);
//     // }
//   } catch (e) {
//     alert("Unspecified error! Please contact admin!");
//   }
// };

// export const getRVPicturesForOrder = async (values, dispatch) => {
//   const res = await axios.get('/api/getRVInventoryUnits');
//   try {
//     //  res = await axios.post('/api/rv-get-pics-by-id', values);
//     //alert(JSON.stringify(res.data));
//     dispatch({ type: GET_RV_PICTURES_FOR_ORDER, payload: res.data });
//   } catch (e) {
//     dispatch({
//       type: GET_RV_PICTURES_FOR_ORDER_ERROR,
//       payload: 'Error: Could not connect to server!'
//     });
//   }
// };
export const getRVPicturesForOrder = (values) => async (dispatch) => {
  try {
    let res = await axios.post("/api/rv-get-pics-by-id", values);
    dispatch({ type: GET_RV_PICTURES_FOR_ORDER, payload: res.data });
  } catch (e) {
    dispatch({
      type: GET_RV_PICTURES_FOR_ORDER_ERROR,
      payload: "Error: Could not connect to server!",
    });
  }
};
