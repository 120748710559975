import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './../validate';
import { renderTextArea } from './../renderField';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

class Description extends Component {
  componentDidMount() {}
  renderContent() {
    return this.renderForm();
  }
  renderForm() {
    return (
      <div>
        <form onSubmit={this.props.handleSubmit}>
          <Form.Group controlId="formDescription">
            <Form.Label>Type a Description</Form.Label>
            <Field
              type="textarea"
              placeholder="Description"
              label="description"
              name="Description"
              rows="5"
              component={renderTextArea}
            />
          </Form.Group>
          <div>
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button className="pull-right next" variant="success" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

Description.defaultProps = {
  rvUnit: [],
};
export default reduxForm({
  form: 'wizard',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  validate,
})(Description);
