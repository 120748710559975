import React from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from '../validate';
import { renderSelectAddNew } from '../renderField';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import AddBrochurePictureForm from '../AddBrochurePictureForm';
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

const BrochureForm = (props) => {
  const { handleSubmit, previousPage, brochures } = props;
  const [show, setShow] = React.useState(false);
  const [error, setError] = React.useState(null);
  // const [addWhat, setAddWhat] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const showAddNewPopup = (e) => {
    if (e.target.value === '0') {
      //  setAddWhat(e.target.name);
      handleShow();
    }
  };
  const renderAlert = () => {
    if (error === null) {
    } else if (error) {
      return <Alert variant="danger">Error Please contact admin!</Alert>;
    } else {
      return <Alert variant="success">Success!</Alert>;
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          {renderAlert()}
          <AddBrochurePictureForm
            brochureName={
              props.year +
              ' ' +
              props.manufacturer +
              ' ' +
              props.brand +
              ' ' +
              props.model +
              ' ' +
              props.color +
              ' ' +
              props.type
            }
            brochures={brochures}
            setError={setError}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <form onSubmit={handleSubmit}>
        <Form.Group controlId="formBrand">
          <Form.Label>Select a brochure</Form.Label>
          <Field
            type="select"
            placeholder="Select a brochure"
            label="brochure"
            name="BrochureID"
            options={brochures}
            component={renderSelectAddNew}
            onChange={showAddNewPopup}
          />
        </Form.Group>
        <div>
          <Button
            className="pull-left previous"
            variant="danger"
            type="submit"
            onClick={previousPage}
          >
            Previous
          </Button>
          <Button className="pull-right next" variant="success" type="submit">
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(BrochureForm);
