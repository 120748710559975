import { combineReducers } from 'redux';
import authReducer from './authReducer';
import creditAppsReducer from './creditAppReducer';
import addRVInventoryReducer from './addRVInventoryReducer';
import addRVInventorySubmitReducer from './addRVInventorySubmitReducer';
import editInventoryReducer from './editInventoryReducer';
import addInventoryOptionReducer from './addInventoryOptionReducer';
import rvPictureOrganizerReducer from './rvPictureOrganizerReducer';

import { reducer as reduxForm } from 'redux-form';
import { reducer as burgerMenu } from 'redux-burger-menu';
export default combineReducers({
  auth: authReducer,
  creditApps: creditAppsReducer,
  dropDownValuesRVAddInventory: addRVInventoryReducer,
  rvAddUnitResults: addRVInventorySubmitReducer,
  rvUnit: editInventoryReducer,
  rvOptionsInsertResults: addInventoryOptionReducer,
  rvGetPicturesForOrganizerResults: rvPictureOrganizerReducer,
  form: reduxForm,
  burgerMenu, // Must be mounted at 'burgerMenu'
});
