import { GET_RV_UNIT, GET_RV_ERROR } from "../actions/types";

export default function editInventoryReducer(state = null, action) {
  switch (action.type) {
    case GET_RV_UNIT:
      return action.payload || false;
    case GET_RV_ERROR:
      return "Error";
    default:
      return state;
  }
}
