import React, { Component } from 'react';

class CreateUser extends Component {
  renderContent() {}

  render() {
    return (
      <div>
        <p>CreateUser</p>
      </div>
    );
  }
}

export default CreateUser;
