import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './../validate';
import { renderField } from './../renderField';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

class Specs3Form extends Component {
  componentDidMount() {}
  renderContent() {
    return this.renderForm();
  }
  renderForm() {
    return (
      <div>
        <h2>Accommodations</h2>
        <form onSubmit={this.props.handleSubmit}>
          <Form.Group controlId="sleeps">
            <Field
              type="text"
              placeholder="Sleeps"
              label="sleeps"
              name="Sleeps"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="beds">
            <Field
              type="text"
              placeholder="Beds"
              label="beds"
              name="Beds"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="primaryBedType">
            <Field
              type="text"
              placeholder="Primary Bed Type"
              label="primaryBedType"
              name="PrimaryBedType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="secondBedType">
            <Field
              type="text"
              placeholder="Second Bed Type"
              label="secondBedType"
              name="SecondBedType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="bathrooms">
            <Field
              type="text"
              placeholder="Bathrooms"
              label="bathrooms"
              name="Bathrooms"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="toiletType">
            <Field
              type="text"
              placeholder="Toilet Type"
              label="toiletType"
              name="ToiletType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="refrigeratorSize">
            <Field
              type="text"
              placeholder="Refrigerator Size"
              label="refrigeratorSize"
              name="RefrigeratorSize"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="stoveBurners">
            <Field
              type="text"
              placeholder="Stove Burners"
              label="stoveBurners"
              name="StoveBurners"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="tvs">
            <Field
              type="text"
              placeholder="TVs"
              label="tvs"
              name="TVs"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="tvSize">
            <Field
              type="text"
              placeholder="TV Size"
              label="tvSize"
              name="TVSize"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="secondTvSize">
            <Field
              type="text"
              placeholder="Second TV Size"
              label="secondTvSize"
              name="SecondTVSize"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="dinetteType">
            <Field
              type="text"
              placeholder="Dinette Type"
              label="dinetteType"
              name="DinetteType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="seatingType">
            <Field
              type="text"
              placeholder="Seating Type"
              label="seatingType"
              name="SeatingType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="secondSeatingType">
            <Field
              type="text"
              placeholder="Second Seating Type"
              label="secondSeatingType"
              name="SecondSeatingType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="primaryAwningLength">
            <Field
              type="text"
              placeholder="Primary Awning Length"
              label="primaryAwningLength"
              name="PrimaryAwningLength"
              maxlength="7"
              component={renderField}
            />
          </Form.Group>
          <div>
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button className="pull-right next" variant="success" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

Specs3Form.defaultProps = {
  rvUnit: [],
};
export default reduxForm({
  form: 'wizard', //                 <------ same form name
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(Specs3Form);
