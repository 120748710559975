import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './../validate';
import { renderField } from './../renderField';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

class Specs2Form extends Component {
  componentDidMount() {}
  renderContent() {
    return this.renderForm();
  }
  renderForm() {
    return (
      <div>
        <h2>Additional Specifications</h2>
        <form onSubmit={this.props.handleSubmit}>
          <Form.Group controlId="acUnits">
            <Field
              type="text"
              placeholder="AC Units"
              label="acUnits"
              name="ACUnits"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="acBTU">
            <Field
              type="text"
              placeholder="AC BTU"
              label="acBTU"
              name="ACBTU"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="secondACBTU">
            <Field
              type="text"
              placeholder="Second AC BTU"
              label="secondACBTU"
              name="SecondACBTU"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="furnaceUnits">
            <Field
              type="text"
              placeholder="Furnace Units"
              label="furnaceUnits"
              name="FurnaceUnits"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="FurnaceBTU">
            <Field
              type="text"
              placeholder="Furnace BTU"
              label="furnaceBTU"
              name="FurnaceBTU"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="secondFurnaceBTU">
            <Field
              type="text"
              placeholder="Second Furnace BTU"
              label="secondFurnaceBTU"
              name="SecondFurnaceBTU"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="tireSize">
            <Field
              type="text"
              placeholder="Tire Size"
              label="tireSize"
              name="TireSize"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="axles">
            <Field
              type="text"
              placeholder="Axles"
              label="axles"
              name="Axles"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="garageLength">
            <Field
              type="text"
              placeholder="Garage Length"
              label="garageLength"
              name="GarageLength"
              maxlength="7"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="engineModel">
            <Field
              type="text"
              placeholder="Engine Model"
              label="engineModel"
              name="EngineModel"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="engineFuelType">
            <Field
              type="text"
              placeholder="Engine Fuel Type"
              label="engineFuelType"
              name="EngineFuelType"
              maxlength="250"
              component={renderField}
            />
          </Form.Group>
          <div>
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button className="pull-right next" variant="success" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

Specs2Form.defaultProps = {
  rvUnit: [],
};
export default reduxForm({
  form: 'wizard', //                 <------ same form name
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(Specs2Form);
