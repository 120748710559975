import React, { Component } from 'react';

class EditPages extends Component {
  renderContent() {}

  render() {
    return (
      <div>
        <p>EditPages</p>
      </div>
    );
  }
}

export default EditPages;
