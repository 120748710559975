import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import * as actions from '../redux/actions/';
import SideBar from './sidebar/Sidebar';
import Welcome from './sidebar/Welcome';
import Profile from './sidebar/Profile';
import Contact from './sidebar/Contact';
import ViewCreditApps from './sidebar/finance/ViewCreditApps';
import CreateUser from './sidebar/user-management/CreateUser';
import EditUserCompanies from './sidebar/user-management/EditUserCompanies';
import EditUserRoles from './sidebar/user-management/EditUserRoles';
import AddPage from './sidebar/web-admin/AddPage';
import EditPages from './sidebar/web-admin/EditPages';
import WebNavigation from './sidebar/web-admin/WebNavigation';
import NewCompany from './sidebar/admin/NewCompany';
import RVAddInventory from './sidebar/rv-Inventory/RVAddInventory';
import EditRVItem from './sidebar/rv-Inventory/EditRVItem';
import RVOptions from './sidebar/rv-Inventory/RVOptions';
import AddFloorPlan from './sidebar/rv-Inventory/AddFloorPlan';
import AddBrochure from './sidebar/rv-Inventory/AddBrochure';
import RVInventoryList from './sidebar/rv-Inventory/RVInventoryView';

class Home extends Component {
  renderContent() {}
  componentDidMount() {
    if (this.props.auth) {
      document.title = this.props.auth.currentCompanyName[0].Name + ' Portal';
    }
  }
  render() {
    return (
      <BrowserRouter>
        <div id="outer-container">
          <SideBar
            pageWrapId={'page-wrap'}
            outerContainerId={'outer-container'}
            logoutPortal={this.props.logoutPortal}
          />
          <main id="page-wrap">
            <div id="container">
              <Route exact path="/" component={withRouter(Welcome)} />
              <Route
                exact
                path="/view-credit-apps"
                component={withRouter(ViewCreditApps)}
              />
              <Route
                exact
                path="/create-user"
                component={withRouter(CreateUser)}
              />
              <Route
                exact
                path="/edit-user-companies"
                component={withRouter(EditUserCompanies)}
              />
              <Route
                exact
                path="/edit-user-roles"
                component={withRouter(EditUserRoles)}
              />
              <Route exact path="/add-page" component={withRouter(AddPage)} />
              <Route
                exact
                path="/edit-page"
                component={withRouter(EditPages)}
              />
              <Route
                exact
                path="/web-navigation"
                component={withRouter(WebNavigation)}
              />
              <Route
                exact
                path="/add-rv-inventory"
                component={withRouter(RVAddInventory)}
              />
              <Route
                path="/view-rv-inventory"
                render={() => <RVInventoryList soldView={false} />}
              />
              <Route
                path="/view-rv-sold-inventory"
                render={() => <RVInventoryList soldView={true} />}
              />

              <Route
                exact
                path="/rv-options"
                component={withRouter(RVOptions)}
              />
              <Route
                exact
                path="/add-rv-floorplan"
                component={withRouter(AddFloorPlan)}
              />
              <Route
                exact
                path="/add-rv-brochure"
                component={withRouter(AddBrochure)}
              />
              <Route
                exact
                path="/edit-rv-inventory"
                component={withRouter(EditRVItem)}
              />
              <Route
                exact
                path="/new-company"
                component={withRouter(NewCompany)}
              />
              <Route exact path="/profile" component={withRouter(Profile)} />
              <Route exact path="/contact" component={withRouter(Contact)} />
            </div>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps, actions)(Home);
