import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SpecsForm1 from './add-inventory-forms/SpecsForm1';
import SpecsForm2 from './add-inventory-forms/SpecsForm2';
import SpecsForm3 from './add-inventory-forms/SpecsForm3';
import ReviewForm from './add-inventory-forms/ReviewForm';
import DescriptionForm from './add-inventory-forms/DescriptionForm';
import PricingForm from './add-inventory-forms/PricingForm';

class RVEditInventoryForm extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1
    };
  }

  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { onSubmit, rvUnit } = this.props;
    const { page } = this.state;

    return (
      <div>
        {page === 1 && <SpecsForm1 onSubmit={this.nextPage} rvUnit={rvUnit} />}
        {page === 2 && (
          <SpecsForm2
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            rvUnit={rvUnit}
          />
        )}
        {page === 3 && (
          <SpecsForm3
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            rvUnit={rvUnit}
          />
        )}

        {page === 4 && (
          <DescriptionForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            rvUnit={rvUnit}
          />
        )}
        {page === 5 && (
          <PricingForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            rvUnit={rvUnit}
          />
        )}
        {page === 6 && (
          <ReviewForm previousPage={this.previousPage} onSubmit={onSubmit} showEdit={this.props.showEdit} />
        )}
      </div>
    );
  }
}

RVEditInventoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default RVEditInventoryForm;
