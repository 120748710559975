import React from "react";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import Alert from "react-bootstrap/Alert";
const renderField = ({
  input,
  placeholder,
  label,
  type,
  maxlength,
  meta: { touched, error }
}) => (
  <div>
    <br />
    <div>
      <FormControl
        {...input}
        placeholder={placeholder}
        type={type}
        maxLength={maxlength}
      />
      {touched && error && <Alert variant="danger">{error}</Alert>}
    </div>
  </div>
);

const renderTextArea = ({
  input,
  rows,
  placeholder,
  name,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <FormControl
      {...input}
      name={name}
      placeholder={placeholder}
      type={type}
      as="textarea"
      rows={rows}
    />
    {touched && error && <Alert variant="danger">{error}</Alert>}
  </div>
);

const renderCheckBox = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <FormCheck {...input} placeholder={label} type={type} />
      {touched && error && <Alert variant="danger">{error}</Alert>}
    </div>
  </div>
);
const renderSelectAddNew = ({
  input,
  options,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <FormControl {...input} as="select">
      <option></option>
      <option value="0" key="0">
        Add New
      </option>
      {options.map(rows => (
        <option value={rows.key} key={rows.key}>
          {rows.value}
        </option>
      ))}
    </FormControl>
    {touched && error && <Alert variant="danger">{error}</Alert>}
  </div>
);
const renderSelect = ({
  input,
  options,
  label,
  type,
  meta: { touched, error }
}) => (
  <div>
    <FormControl {...input} as="select">
      <option></option>
      {options.map(rows => (
        <option value={rows.key} key={rows.key}>
          {rows.value}
        </option>
      ))}
    </FormControl>
    {touched && error && <Alert variant="danger">{error}</Alert>}
  </div>
);
export {
  renderField,
  renderCheckBox,
  renderSelect,
  renderTextArea,
  renderSelectAddNew
};
