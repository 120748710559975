const validate = (values) => {
  const errors = {};

  if (!values.TypeID || values.TypeID === '0') {
    errors.TypeID = 'Required';
  } else {
  }
  if (!values.ManufacturerID || values.ManufacturerID === '0') {
    errors.ManufacturerID = 'Required';
  }
  if (!values.BrandID || values.BrandID === '0') {
    errors.BrandID = 'Required';
  }
  if (!values.ModelID || values.ModelID === '0') {
    errors.ModelID = 'Required';
  }
  if (!values.ColorID || values.ColorID === '0') {
    errors.ColorID = 'Required';
  }
  if (!values.TheYear) {
    errors.TheYear = 'Required';
  } else if (
    !/^194[0-9]|19[5-9][0-9]|20[0-6][0-9]|207[0-5]/i.test(values.TheYear)
  ) {
    errors.TheYear = 'Only years between 1940 - 2075';
  }

  if (!values.FloorPlanID) {
    errors.FloorPlanID = 'Required';
  }
  if (!values.BrochureID) {
    errors.BrochureID = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.MSRP) {
    errors.lastName = 'Required';
  }
  if (!values.SalePrice) {
    errors.lastName = 'Required';
  }
  if (
    values.ExteriorLength &&
    !/^(\d+)'(\d+)\x22/.test(values.ExteriorLength)
  ) {
    errors.ExteriorLength =
      'Format does not match, make sure the format is ##\'##" (no spaces)';
  }
  if (
    values.ExteriorHeight &&
    !/^(\d+)'(\d+)\x22/.test(values.ExteriorHeight)
  ) {
    errors.ExteriorHeight =
      'Format does not match, make sure the format is ##\'##" (no spaces)';
  }
  if (values.ExteriorWidth && !/^(\d+)'(\d+)\x22/.test(values.ExteriorWidth)) {
    errors.ExteriorWidth =
      'Format does not match, make sure the format is ##\'##" (no spaces)';
  }
  if (!values.StockNumber) {
    errors.StockNumber = 'Required';
  } else if (/[^a-z0-9]/gi.test(values.StockNumber)) {
    errors.StockNumber = 'Only Letters and numbers';
  }

  if (values.HitchWeight && !/^[\d.]+$/.test(values.HitchWeight)) {
    errors.HitchWeight = 'Numeric Only';
  }

  if (values.ShipWeight && !/^[\d.]+$/.test(values.ShipWeight)) {
    errors.ShipWeight = 'Numeric Only';
  }

  if (values.GrossWeight && !/^[\d.]+$/.test(values.GrossWeight)) {
    errors.GrossWeight = 'Numeric Only';
  }
  if (values.CargoCapacity && !/^[\d.]+$/.test(values.CargoCapacity)) {
    errors.CargoCapacity = 'Numeric Only';
  }
  if (values.Slides && !/^\d+$/.test(values.Slides)) {
    errors.Slides = 'Numeric Only, No Decimals';
  }
  if (
    values.FreshWaterCapacity &&
    !/^[\d.]+$/.test(values.FreshWaterCapacity)
  ) {
    errors.FreshWaterCapacity = 'Numeric Only';
  }
  if (values.GrayWaterCapacity && !/^[\d.]+$/.test(values.GrayWaterCapacity)) {
    errors.GrayWaterCapacity = 'Numeric Only';
  }
  if (
    values.BlackWaterCapacity &&
    !/^[\d.]+$/.test(values.BlackWaterCapacity)
  ) {
    errors.BlackWaterCapacity = 'Numeric Only';
  }
  if (values.LPCapacity && !/^[\d.]+$/.test(values.LPCapacity)) {
    errors.LPCapacity = 'Numeric Only';
  }

  if (values.GarageLength && !/^(\d+)'(\d+)\x22/.test(values.GarageLength)) {
    errors.GarageLength =
      'Format does not match, make sure the format is ##\'##" (no spaces)';
  }

  if (values.ACBTU && !/^[\d.]+$/.test(values.ACBTU)) {
    errors.ACBTU = 'Numeric Only';
  }
  if (values.SecondACBTU && !/^[\d.]+$/.test(values.SecondACBTU)) {
    errors.SecondACBTU = 'Numeric Only';
  }
  if (values.FurnaceBTU && !/^[\d.]+$/.test(values.FurnaceBTU)) {
    errors.FurnaceBTU = 'Numeric Only';
  }
  if (values.SecondFurnaceBTU && !/^[\d.]+$/.test(values.SecondFurnaceBTU)) {
    errors.SecondFurnaceBTU = 'Numeric Only';
  }
  if (values.Axles && !/^\d+$/.test(values.Axles)) {
    errors.Axles = 'Numeric Only, No Decimals';
  }
  if (values.Sleeps && !/^\d+$/.test(values.Sleeps)) {
    errors.Sleeps = 'Numeric Only, No Decimals';
  }
  if (values.Beds && !/^\d+$/.test(values.Beds)) {
    errors.Beds = 'Numeric Only, No Decimals';
  }
  if (values.Bathrooms && !/^\d+$/.test(values.Bathrooms)) {
    errors.Bathrooms = 'Numeric Only, No Decimals';
  }
  if (values.StoveBurners && !/^\d+$/.test(values.StoveBurners)) {
    errors.StoveBurners = 'Numeric Only, No Decimals';
  }
  if (values.TVs && !/^\d+$/.test(values.TVs)) {
    errors.TVs = 'Numeric Only, No Decimals';
  }
  if (values.TVSize && !/^\d+$/.test(values.TVSize)) {
    errors.TVSize = 'Numeric Only, No Decimals';
  }
  if (values.SecondTVSize && !/^\d+$/.test(values.SecondTVSize)) {
    errors.SecondTVSize = 'Numeric Only, No Decimals';
  }
  if (
    values.PrimaryAwningLength &&
    !/^(\d+)'(\d+)\x22/.test(values.PrimaryAwningLength)
  ) {
    errors.PrimaryAwningLength =
      'Format does not match, make sure the format is ##\'##" (no spaces)';
  }
  if (values.MSRP && !/^[\d.]+$/.test(values.MSRP)) {
    errors.MSRP = 'Numeric Only';
  }
  if (values.SalePrice && !/^[\d.]+$/.test(values.SalePrice)) {
    errors.SalePrice = 'Numeric Only';
  }

  // //only Numbers
  // if (values.SecondTVSize && /^\d+$/.test(values.SecondTVSize)) {
  //   errors.SecondTVSize = 'Numeric Only, No Decimals';
  // }

  // //decimals

  // if (values.BlackWaterCapacity && /^[\d.]+$/.test(values.BlackWaterCapacity)) {
  //   errors.BlackWaterCapacity = 'Numeric Only';
  // }

  return errors;
};

export default validate;
