import React, { Component } from 'react';

class NewCompany extends Component {
  renderContent() {}

  render() {
    return (
      <div>
        <p>NewCompany</p>
      </div>
    );
  }
}

export default NewCompany;
