import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import InventoryList from './RVInventoryList';
import EditRVItem from './EditRVItem';
import PhotosForm from './add-inventory-forms/PhotosForm';
import Button from 'react-bootstrap/Button';
import RVPictureOrganizer from './RVPictureOrganizer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axios from 'axios';
import PropTypes from 'prop-types';

class RVInventoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      showPictures: false,
      showPictureOrganizer: false,
      errors: null,
      rvUnits: null,
      currentSoldID: null,
    };
    this.viewInventory = this.viewInventory.bind(this);
    this.updateClearance = this.updateClearance.bind(this);
    this.updateSold = this.updateSold.bind(this);
    this.editRVItem = this.editRVItem.bind(this);
    this.addPictures = this.addPictures.bind(this);
    this.organizePictures = this.organizePictures.bind(this);
    this.deleteRVItemService = this.deleteRVItemService.bind(this);
    this.removeSoldInventory = this.removeSoldInventory.bind(this);
  }
  async getRVInventoryUnits() {
    try {
      let res;
      if (this.props.soldView) {
        res = await axios.get('/api/getRVInventoryUnitsSold');
      } else {
        res = await axios.get('/api/getRVInventoryUnits');
      }
      if (res.data.status === 'Success') {
        this.setState({ rvUnits: res.data.data });
      } else {
        this.setState({
          errors: 'Error Getting Units!',
        });
      }
    } catch {
      this.setState({
        errors: 'Error Getting Units!',
      });
    }
  }
  viewInventory() {
    this.setState({
      showEdit: false,
      showPictures: false,
      showPictureOrganizer: false,
    });
    this.getRVInventoryUnits();
  }
  componentDidMount() {
    this.getRVInventoryUnits();
  }

  isClearance(isClearance, StockNumber) {
    if (isClearance === 'Yes') {
      return (
        <Col xs={3}>
          <Button
            variant="link"
            onClick={() => this.updateClearance(StockNumber, false)}
          >
            <img
              src="https://static231media.com/public/images/231portal/icons/clearance.png"
              alt="Clearance"
              title="Mark Clearance"
              width="16"
              height="16"
            />
          </Button>
        </Col>
      );
    } else {
      return (
        <Col xs={3}>
          <Button
            variant="link"
            onClick={() => this.updateClearance(StockNumber, true)}
          >
            <img
              src="https://static231media.com/public/images/231portal/icons/not-clearance.png"
              alt="Not Sold"
              title="Mark Not Clearance"
              width="16"
              height="16"
            />
          </Button>
        </Col>
      );
    }
  }

  async updateClearance(id, isClearance) {
    try {
      let res = await axios.post('/api/update-unit-clearance-status', {
        StockNumber: id,
        Clearance: isClearance,
      });

      if (res.data.status === 'Success') {
        this.setState((prevState) => {
          const newRVUnits = [...prevState.rvUnits];
          let index = newRVUnits.findIndex((x) => x.StockNumber === id);
          newRVUnits[index]['Clearance'] = this.clearanceChange(
            newRVUnits[index]['Clearance']
          );
          return { rvUnits: newRVUnits };
        });
      } else {
        this.setState({
          errors: 'Error Setting Clearance Status on Stock Number: ' + id,
        });
      }
    } catch (error) {
      this.setState({
        errors: 'Error Setting Clearance Status on Stock Number: ' + id,
      });
    }
  }
  clearanceChange(status) {
    return status === 'No' ? 'Yes' : 'No';
  }

  removeSoldInventory(UnitID) {
    //remove unit from array
    this.setState((prevState) => {
      let newRVUnits = [...prevState.rvUnits];
      let index = newRVUnits.findIndex((x) => x.UnitID === UnitID);
      //delete unit from array
      newRVUnits.splice(index, 1);
      return { rvUnits: newRVUnits };
    });
  }

  async updateSold(UnitID, StockNumber) {
    try {
      let res = await axios.post('/api/rv-sell-unit', {
        UnitID: UnitID,
      });
      if (res.data.msg === 'Success') {
        this.removeSoldInventory(UnitID);
      } else {
        this.setState({
          errors: 'Error Setting Sold Status on Stock Number: ' + StockNumber,
        });
      }
    } catch (error) {
      this.setState({
        errors:
          'Error Setting Clearance Status on Stock Number: ' + StockNumber,
      });
    }
  }

  async deleteRVItemService(UnitID, StockNumber) {
    const r = window.confirm('Do you really want to delete this unit?');
    if (r === true) {
      try {
        let res = await axios.post('/api/delete-rv-unit', {
          UnitID: UnitID,
        });

        if (res.data.status === 'Success') {
          this.setState((prevState) => {
            let newRVUnits = [...prevState.rvUnits];
            let index = newRVUnits.findIndex(
              (x) => x.StockNumber === StockNumber
            );
            //delete unit from array
            newRVUnits.splice(index, 1);
            return { rvUnits: newRVUnits };
          });
        } else {
          this.setState({
            errors:
              'Error Deleting Unit: ' +
              StockNumber +
              '. Please refresh page and try again, if the issue persists, please contact an admin.',
          });
        }
      } catch {
        this.setState({
          errors:
            'Error Deleting Unit: ' +
            StockNumber +
            '. Please refresh page and try again, if the issue persists, please contact an admin.',
        });
      }
    }
  }

  renderContent() {
    if (this.state.showEdit) {
      return (
        <div>
          <Button
            className="pull-right previous"
            variant="danger"
            type="submit"
            onClick={this.viewInventory}
          >
            Exit
          </Button>
          <EditRVItem id={this.state.ID} showEdit={this.viewInventory} />
        </div>
      );
    } else if (this.state.showPictures) {
      return (
        <div>
          <PhotosForm id={this.state.ID} viewInventory={this.viewInventory} />
          <Button
            className="pull-left previous"
            variant="danger"
            type="submit"
            onClick={this.viewInventory}
          >
            Back
          </Button>
        </div>
      );
    } else if (this.state.showPictureOrganizer) {
      return (
        <div>
          <DndProvider backend={HTML5Backend}>
            <RVPictureOrganizer
              id={this.state.ID}
              viewInventory={this.viewInventory}
            />
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.viewInventory}
            >
              Back
            </Button>
          </DndProvider>
        </div>
      );
    } else {
      switch (this.state.rvUnits) {
        case null:
          return <p>No Units</p>;
        case false:
          return <p>No Units</p>;
        case 'Error':
          return <p>Error performing action on unit, please refresh page!</p>;
        default:
          if (!this.state.errors) {
            return (
              <InventoryList
                rvUnits={this.state.rvUnits}
                editRVItem={this.editRVItem}
                addPictures={this.addPictures}
                organizePictures={this.organizePictures}
                deleteRVItemService={this.deleteRVItemService}
                updateClearance={this.updateClearance}
                updateSold={this.updateSold}
                soldView={this.props.soldView}
              />
            );
          } else {
            return <p>{this.state.errors} </p>;
          }
      }
    }
  }
  editRVItem = (id) => {
    this.setState({
      ...this.state,
      showEdit: true,
      showPictures: false,
      showPictureOrganizer: false,
      ID: id,
    });
  };

  addPictures = (id) => {
    this.setState({
      ...this.state,
      showEdit: false,
      showPictures: true,
      showPictureOrganizer: false,
      ID: id,
    });
  };

  organizePictures = (id) => {
    this.setState({
      ...this.state,
      showEdit: false,
      showPictures: false,
      showPictureOrganizer: true,
      ID: id,
    });
  };
  render() {
    return <div className="inventory">{this.renderContent()}</div>;
  }
}

export default RVInventoryList;

RVInventoryList.propTypes = {
  soldView: PropTypes.bool,
};
