import {
  FETCH_CREDIT_APPS,
  DELETE_CREDIT_APP,
  DELETE_CREDIT_APP_ERROR,
} from "../actions/types";

export default function creditAppReducer(state = null, action) {
  switch (action.type) {
    case FETCH_CREDIT_APPS:
      return action.payload || false;
    case DELETE_CREDIT_APP:
      var index = state.data
        .map((x) => {
          return x.idCreditApp;
        })
        .indexOf(action.payload.idCreditApp);
      state.data.splice(index, 1);
      return { ...state, data: state.data };
    case DELETE_CREDIT_APP_ERROR:
      return "Error";
    default:
      return state;
  }
}
