import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import FormControl from 'react-bootstrap/FormControl';
import Menu from 'react-burger-menu/lib/menus/push';
import SubMenu from './SubMenu';
import axios from 'axios';
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      selectedCompany: this.props.auth.currentCompanyName[0].Name,
    };
    this.ChangeCompany = this.ChangeCompany.bind(this);
    this.Logout = this.Logout.bind(this);

    if (!this.props.auth.currentCompanyRoles) {
      this.props.auth.currentCompanyRoles = [];
    }
  }
  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }
  renderContent() {}

  displayAdmin() {
    if (this.props.auth.currentCompanyRoles.includes('Admin')) {
      return (
        <SubMenu name="Admin">
          <Link
            to="/new-company"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            New Company
          </Link>
        </SubMenu>
      );
    }
  }

  displayFinance() {
    if (
      this.props.auth.currentCompanyRoles.includes('Admin') ||
      this.props.auth.currentCompanyRoles.includes('Finance')
    ) {
      return (
        <SubMenu name="Finance">
          <Link
            to="/view-credit-apps"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            View Credit Apps
          </Link>
        </SubMenu>
      );
    }
  }
  displayRVManageInventory() {
    if (
      this.props.auth.currentCompanyRoles.includes('Admin') ||
      this.props.auth.currentCompanyRoles.includes('Manage Inventory')
    ) {
      return (
        <SubMenu name="RV Inventory">
          <Link
            to="/view-rv-inventory"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            View Inventory
          </Link>
          <Link
            to="/add-rv-inventory"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Add Inventory
          </Link>
          <Link
            to="/rv-options"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            RV Options
          </Link>
          <Link
            to="/add-rv-floorplan"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Add Floorpan
          </Link>
          <Link
            to="/add-rv-brochure"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Add Brochure
          </Link>
          <Link
            to="/view-rv-sold-inventory"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            View Sold Inventory
          </Link>
        </SubMenu>
      );
    }
  }
  displayUserAdmin() {
    if (
      this.props.auth.currentCompanyRoles.includes('Admin') ||
      this.props.auth.currentCompanyRoles.includes('User Admin')
    ) {
      return (
        <SubMenu name="User Management">
          <Link
            to="/create-user"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Create User
          </Link>
          <Link
            to="/edit-user-companies"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Edit User's Companies
          </Link>
          <Link
            to="/edit-user-roles"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Edit User's Roles
          </Link>
        </SubMenu>
      );
    }
  }
  displayWebAdmin() {
    if (
      this.props.auth.currentCompanyRoles.includes('Admin') ||
      this.props.auth.currentCompanyRoles.includes('Web Admin')
    ) {
      return (
        <SubMenu name="Web Admin">
          <Link
            to="/add-page"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Add Page
          </Link>
          <Link
            to="/edit-page"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Edit Page
          </Link>
          <Link
            to="/web-navigation"
            onClick={() => this.closeMenu()}
            className="btn-floating btn-large red"
          >
            Navigation
          </Link>
        </SubMenu>
      );
    }
  }

  async ChangeCompany(e) {
    const selectedIndex = e.target.options.selectedIndex;
    const selectedCompany = e.target.value;
    try {
      let res = await axios.post('/api/user-update-company', {
        companyID: e.target.options[selectedIndex].getAttribute('datakey'),
      });
      if (res.data === 'Success') {
        this.setState({ selectedCompany: selectedCompany }); // this will update the state of selected therefore updating value in select
      } else {
        alert(`Error: Could not Change Company Name to ${e.target.value}.`);
      }
    } catch (error) {
      alert(`Error: Could not Change Company Name to ${e.target.value}`);
    }
  }
  async Logout() {
    await axios.get('/api/logout');
    this.props.logoutPortal();
  }
  render() {
    const { selectedCompany } = this.state;
    const value = selectedCompany; //&& selectedCompany.value; // this will read from state and set the value in state as the selected value. Therefore setting a value even when none has yet to be selected.
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        <FormControl
          as="select"
          value={value}
          onChange={(e) => this.ChangeCompany(e)}
        >
          {typeof this.props.auth.userCompanies !== 'undefined'
            ? this.props.auth.userCompanies.map((rows) => (
                <option key={rows.idCompanies} datakey={rows.idCompanies}>
                  {rows.Name}
                </option>
              ))
            : ''}
        </FormControl>
        <Button variant="primary" size="sm" block>
          Change Company
        </Button>
        {this.renderContent()}
        <Link
          to="/"
          className="btn-floating btn-large red"
          onClick={() => this.closeMenu()}
        >
          Home
        </Link>
        {this.displayFinance()}
        {this.displayRVManageInventory()}
        {this.displayWebAdmin()}
        {this.displayUserAdmin()}
        {this.displayAdmin()}
        <Link
          to="/profile"
          onClick={() => this.closeMenu()}
          className="btn-floating btn-large red"
        >
          Profile
        </Link>
        <Link
          to="/contact"
          onClick={() => this.closeMenu()}
          className="btn-floating btn-large red"
        >
          Contact
        </Link>

        <Button variant="danger" size="md" block onClick={() => this.Logout()}>
          Logout
        </Button>
      </Menu>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps)(Sidebar);
