import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import Button from "react-bootstrap/Button";
import RVPictureOrganizer from "../RVPictureOrganizer";

class PhotosForm extends Component {
  componentDidMount() {}

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      showPicOrganizer: false,
    };
    this.showPicOrganizer = this.showPicOrganizer.bind(this);
  }
  showPicOrganizer() {
    this.setState({ showPicOrganizer: true });
  }
  renderContent() {
    var componentConfig = {
      iconFiletypes: [".jpg", ".png", ".gif"],
      showFiletypeIcon: true,
      postUrl: "/api/upload/",
    };
    var djsConfig = {
      addRemoveLinks: true,
      acceptedFiles: "image/*",
      params: {
        type: "unitPicture",
        UnitID: this.props.id,
      },
    };

    if (!this.state.showPicOrganizer) {
      return (
        <div>
          <h2>Unit Photos</h2>
          <DropzoneComponent
            config={componentConfig}
            //eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          />
          <Button
            className="pull-right next"
            variant="success"
            onClick={this.showPicOrganizer}
          >
            Organize Pictures
          </Button>
        </div>
      );
    } else {
      return (
        <RVPictureOrganizer
          id={this.props.id}
          viewInventory={this.props.viewInventory}
        />
      );
    }
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

export default PhotosForm;
