import React, { Component } from "react";
import * as actions from "../../../redux/actions";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { Field, reduxForm } from "redux-form";
import validate from "../rv-Inventory/validate";
import { renderSelect, renderField } from "../rv-Inventory/renderField";
class RVOptions extends Component {
  renderContent() {
    if (this.props.rvOptionsInsertResults === "Success") {
      return <Alert variant="success">Success!</Alert>;
    } else if (this.props.rvOptionsInsertResults) {
      return (
        <Alert variant="danger">{this.props.rvOptionsInsertResults}</Alert>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        {this.renderContent()}
        <div>
          <Form onSubmit={handleSubmit(actions.insertRVOption)}>
            <Form.Group controlId="from.OptionType">
              <Form.Label>Select an Option Type you wish to add</Form.Label>
              <Field
                type="select"
                label="optionType"
                name="optionType"
                options={[
                  { key: "manufacturer", value: "Manufacturer" },
                  { key: "brand", value: "Brand" },
                  { key: "model", value: "Model" },
                  { key: "color", value: "Color" },
                  { key: "type", value: "Trailer Type" },
                ]}
                component={renderSelect}
              />
            </Form.Group>
            <Form.Group controlId="form.OptionValue">
              <Form.Label>Value:</Form.Label>
              <Field
                type="text"
                placeholder="Value"
                label="optionValue"
                name="optionValue"
                component={renderField}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}

// const decoratedComponent = connect(
//   mapStateToProps,
//   actions
// )(RVOptions);

// export default reduxForm({
//   form: 'change_password_form',
//   validate
// })(DecoratedComponent)

export default reduxForm({
  form: "change_password_form",
  validate,
})(connect(mapStateToProps, actions)(RVOptions));

function mapStateToProps({ rvOptionsInsertResults }) {
  return { rvOptionsInsertResults };
}
