import React, { Component } from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import validate from './../validate';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import PhotosForm from './PhotosForm';

const renderError = (theValue) => {
  if (theValue == null) {
    return 'Blank';
  } else {
    return theValue;
  }
};

const FetchValues = connect((state) => ({
  values: getFormValues('wizard')(state),
}))(({ values }) => (
  <div>
    <p>Please verify the below values you would like to submit!</p>
    <p> Year* : {renderError(values.TheYear)} </p>
    <p> Brand ID* : {renderError(values.BrandID)} </p>
    <p> Type ID* : {renderError(values.TypeID)} </p>
    <p> Model ID* : {renderError(values.ModelID)} </p>
    <p> Color ID* : {renderError(values.ColorID)} </p>
    <p> Sleeps : {renderError(values.Sleeps)} </p>
    <p> Hitch Weight : {renderError(values.HitchWeight)} </p>
    <p> Ship Weight : {renderError(values.ShipWeight)} </p>
    <p> Gross Weight : {renderError(values.GrossWeight)} </p>
    <p> Cargo Capacity : {renderError(values.CargoCapacity)} </p>
    <p> Exterior Length : {renderError(values.ExteriorLength)} </p>
    <p> Exterior Height : {renderError(values.ExteriorHeight)} </p>
    <p> Exterior Width : {renderError(values.ExteriorWidth)} </p>
    <p> Slides : {renderError(values.Slides)} </p>
    <p> Fresh Water Capacity : {renderError(values.FreshWaterCapacity)} </p>
    <p> Gray Water Capacity : {renderError(values.GrayWaterCapacity)} </p>
    <p> Black Water Capacity : {renderError(values.BlackWaterCapacity)} </p>
    <p> LPCapacity : {renderError(values.LPCapacity)} </p>
    <p> Beds : {renderError(values.Beds)} </p>
    <p> Primary Bed Type : {renderError(values.PrimaryBedType)} </p>
    <p> Second Bed Type : {renderError(values.SecondBedType)} </p>
    <p> Bathrooms : {renderError(values.Bathrooms)} </p>
    <p> Toilet Type : {renderError(values.ToiletType)} </p>
    <p> Refrigerator Size : {renderError(values.RefrigeratorSize)} </p>
    <p> Stove Burners : {renderError(values.StoveBurners)} </p>
    <p> TVs : {renderError(values.TVs)} </p>
    <p> TVSize : {renderError(values.TVSize)} </p>
    <p> Second TVSize : {renderError(values.SecondTVSize)} </p>
    <p> Dinette Type : {renderError(values.DinetteType)} </p>
    <p> Seating Type : {renderError(values.SeatingType)} </p>
    <p> Second Seating Type : {renderError(values.SecondSeatingType)} </p>
    <p> Primary Awning Length : {renderError(values.PrimaryAwningLength)} </p>
    <p> ACUnits : {renderError(values.ACUnits)} </p>
    <p> ACBTU : {renderError(values.ACBTU)} </p>
    <p> Second ACBTU : {renderError(values.SecondACBTU)} </p>
    <p> Furnace Units : {renderError(values.FurnaceUnits)} </p>
    <p> Furnace BTU : {renderError(values.FurnaceBTU)} </p>
    <p> Second BTU : {renderError(values.SecondFurnaceBTU)} </p>
    <p> Tire Size : {renderError(values.TireSize)} </p>
    <p> Axles : {renderError(values.Axles)} </p>
    <p> Garage Length : {renderError(values.GarageLength)} </p>
    <p> Engine Model : {renderError(values.EngineModel)} </p>
    <p> Engine Fuel Type : {renderError(values.EngineFuelType)} </p>
    <p> MSRP : {renderError(values.MSRP)} </p>
    <p> Sale Price : {renderError(values.SalePrice)} </p>
    <p> Sold : {renderError(values.Sold)} </p>
    <p> New Used : {renderError(values.NewUsed)} </p>
    <p> Clearance : {renderError(values.Clearance)} </p>
    <p> VIN : {renderError(values.VIN)} </p>
    <p> Stock Number : {renderError(values.StockNumber)} </p>
    <p> Description : {renderError(values.Description)} </p>
  </div>
));
class ReviewForm extends Component {
  componentDidMount() {}

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      show: false,
      message: null,
      status: null,
    };
    this.hideModal = this.hideModal.bind(this);
  }
  componentDidUpdate(nextProps) {
    const { rvAddUnitResults } = this.props;
    if (nextProps.rvAddUnitResults !== rvAddUnitResults) {
      if (
        rvAddUnitResults.status === 'error' ||
        rvAddUnitResults.status === 'success'
      ) {
        this.setState({
          status: rvAddUnitResults.status,
          show: true,
          message: rvAddUnitResults.msg,
        });
      } else if (rvAddUnitResults.status === 'updated') {
        this.props.showEdit();
      }
    }
  }
  renderForm() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div>
          <FetchValues />
          <Button
            className="pull-left previous"
            variant="danger"
            type="submit"
            onClick={this.props.previousPage}
          >
            Previous
          </Button>
          <Button
            className="pull-right next"
            variant="success"
            type="submit"
            disabled={this.props.pristine || this.props.submitting}
          >
            Next
          </Button>
        </div>
      </form>
    );
  }
  renderContent() {
    if (this.state.status === 'success') {
      return (
        <PhotosForm
          id={this.state.message}
          viewInventory={this.props.showEdit}
        />
      );
    } else {
      return (
        <div>
          <Modal show={this.state.show} onHide={this.hideModal}>
            <Modal.Body>
              <p>{this.state.message}</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.hideModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          {this.renderForm()}
        </div>
      );
    }
  }
  hideModal() {
    this.setState({ show: false });
  }
  render() {
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps({ rvAddUnitResults }) {
  return { rvAddUnitResults };
}
//export default connect(mapStateToProps)(Sidebar);

export default reduxForm({
  form: 'wizard', //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(connect(mapStateToProps)(ReviewForm));
