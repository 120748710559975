import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MainInfoForm from './add-inventory-forms/MainInfoForm';
import SpecsForm1 from './add-inventory-forms/SpecsForm1';
import SpecsForm2 from './add-inventory-forms/SpecsForm2';
import SpecsForm3 from './add-inventory-forms/SpecsForm3';
import ReviewForm from './add-inventory-forms/ReviewForm';
import DescriptionForm from './add-inventory-forms/DescriptionForm';
import PricingForm from './add-inventory-forms/PricingForm';
import BrochureForm from './add-inventory-forms/BrochureForm';
import FloorPlanForm from './add-inventory-forms/FloorPlanForm';

class RVInventoryForm extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.setupMainInfoForAdding = this.setupMainInfoForAdding.bind(this);
    this.state = {
      page: 1,
      type: null,
      year: null,
      brand: null,
      model: null,
      color: null,
      manufacturer: null,
    };
    this.pictures = [];
  }
  nextPage() {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  setupMainInfoForAdding(variable, value) {
    switch (variable) {
      case 'TypeID':
        this.setState({ type: value });
        break;
      case 'TheYear':
        this.setState({ year: value });
        break;
      case 'BrandID':
        this.setState({ brand: value });
        break;
      case 'ModelID':
        this.setState({ model: value });
        break;
      case 'ColorID':
        this.setState({ color: value });
        break;
      case 'ManufacturerID':
        this.setState({ manufacturer: value });
        break;
      default:
    }
  }

  render() {
    const {
      onSubmit,
      brands,
      colors,
      manufactures,
      models,
      types,
      brochures,
      floorplans,
    } = this.props;
    const { page } = this.state;
    return (
      <div>
        {page === 1 && (
          <MainInfoForm
            onSubmit={this.nextPage}
            manufactures={manufactures}
            brands={brands}
            colors={colors}
            models={models}
            types={types}
            setupMainInfoForAdding={this.setupMainInfoForAdding}
          />
        )}
        {page === 2 && (
          <SpecsForm1
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 3 && (
          <SpecsForm2
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 4 && (
          <SpecsForm3
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}

        {page === 5 && (
          <DescriptionForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 6 && (
          <BrochureForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            brochures={brochures}
            manufactures={manufactures}
            brands={brands}
            colors={colors}
            models={models}
            types={types}
            type={this.state.type}
            year={this.state.year}
            brand={this.state.brand}
            model={this.state.model}
            color={this.state.color}
            manufacturer={this.state.manufacturer}
          />
        )}
        {page === 7 && (
          <FloorPlanForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
            floorplans={floorplans}
            manufactures={manufactures}
            brands={brands}
            colors={colors}
            models={models}
            types={types}
            type={this.state.type}
            year={this.state.year}
            brand={this.state.brand}
            model={this.state.model}
            color={this.state.color}
            manufacturer={this.state.manufacturer}
          />
        )}
        {page === 8 && (
          <PricingForm
            previousPage={this.previousPage}
            onSubmit={this.nextPage}
          />
        )}
        {page === 9 && (
          <ReviewForm previousPage={this.previousPage} onSubmit={onSubmit} />
        )}
      </div>
    );
  }
}

RVInventoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RVInventoryForm;
