import React, { Component } from "react";
import * as actions from "../redux/actions/";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
class Login extends Component {
  // Using a class based component here because we're accessing DOM refs
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };
  }
  failedLogin() {
    if (this.props.success === "false") {
      return (
        <ul>
          <li>Failed Login</li>
        </ul>
      );
    }
  }
  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    let username = this.state.username;
    let password = this.state.password;
    this.props.submitLogin({ username, password });
  }

  render() {
    return (
      <div>
        <div className="page-header text-center">
          <br />
          <h1>Login</h1>
          <br />
        </div>
        <Container>
          <section className="errors">{this.failedLogin()}</section>
          <Form
            id="login"
            method="post"
            autoComplete="on"
            onSubmit={this.handleSubmit.bind(this)}
          >
            {" "}
            <Row>
              <Col>
                <Form.Group controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    autoComplete="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </Form.Group>

                <Form.Group controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </Form.Group>
                <Button
                  variant="primary btn-lg btn-block"
                  type="submit"
                  disabled={!this.validateForm()}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    );
  }
}

export default connect(null, actions)(Login);
