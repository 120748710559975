import React, { Component } from 'react';
import RVInventoryForm from './RVInventoryForm';
import store from '../../../redux/store';
import { Provider } from 'react-redux';
import * as actions from '../../../redux/actions/';
import { connect } from 'react-redux';

class RVAddInventory extends Component {
  componentDidMount() {
    this.props.getDropDownValuesRVAddInventory();
  }
  renderContent() {
    switch (this.props.dropDownValuesRVAddInventory) {
      case null:
        return <p>loading...</p>;
      case false:
        return <p>loading...</p>;
      case 'Error':
        return <p>Error getting values, please refresh page!</p>;
      default:
        switch (this.props.rvAddUnitResults) {
          default:
            return this.renderForm();
        }
    }
  }

  renderForm() {
    return (
      <Provider store={store}>
        <div style={{ padding: 15 }}>
          <h2 className="text-center">Add Inventory</h2>
          <RVInventoryForm
            onSubmit={actions.showResults}
            brands={this.props.dropDownValuesRVAddInventory.data[0]}
            colors={this.props.dropDownValuesRVAddInventory.data[1]}
            manufactures={this.props.dropDownValuesRVAddInventory.data[2]}
            models={this.props.dropDownValuesRVAddInventory.data[3]}
            types={this.props.dropDownValuesRVAddInventory.data[4]}
            brochures={this.props.dropDownValuesRVAddInventory.data[5]}
            floorplans={this.props.dropDownValuesRVAddInventory.data[6]}
          />
        </div>
      </Provider>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}
function mapStateToProps({ dropDownValuesRVAddInventory, rvAddUnitResults }) {
  return { dropDownValuesRVAddInventory, rvAddUnitResults };
}
export default connect(mapStateToProps, actions)(RVAddInventory);
