import React from "react";

const Profile = props => {
  return (
    <div>
      <p>Not set up yet</p>
    </div>
  );
};

export default Profile;
