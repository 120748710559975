import {
  GET_RV_PICTURES_FOR_ORDER,
  GET_RV_PICTURES_FOR_ORDER_ERROR,
} from "../actions/types";

export default function rvPictureOrganizerReducer(state = null, action) {
  switch (action.type) {
    case GET_RV_PICTURES_FOR_ORDER:
      return action.payload || false;
    case GET_RV_PICTURES_FOR_ORDER_ERROR:
      return action.payload;
    default:
      return state;
  }
}
