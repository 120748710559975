import React, { Component } from "react";
import RVEditInventoryForm from "./RVEditInventoryForm";
import store from "../../../redux/store";
import { Provider } from "react-redux";
import * as actions from "../../../redux/actions/";
import { connect } from "react-redux";

class EditRVItem extends Component {
  componentDidMount() {
    this.props.getRVUnit(this.props.id);
  }
  renderContent() {
    switch (this.props.rvUnit) {
      case null:
        return <p>loading...</p>;
      case false:
        return <p>loading...</p>;
      case "Error":
        return <p>Error getting values, please refresh page!</p>;
      default:
        switch (this.props.rvAddUnitResults) {
          case "Success":
            return <p>Hello Success</p>;
          case "error":
            return <p>Hello Error</p>;
          default:
            return this.renderForm();
        }
    }
  }
  renderForm() {
    return (
      <Provider store={store}>
        <div style={{ padding: 15 }}>
          <h2 className="text-center">Edit Inventory</h2>
          <RVEditInventoryForm
            onSubmit={actions.updateRVUnit}
            rvUnit={this.props.rvUnit.data[0]}
            showEdit={this.props.showEdit}
          />
        </div>
      </Provider>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}
function mapStateToProps({ rvUnit, rvAddUnitResults }) {
  return {
    rvUnit,
    rvAddUnitResults,
  };
}
export default connect(mapStateToProps, actions)(EditRVItem);
