import React, { Component } from 'react';
import * as actions from '../../../redux/actions';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
class ViewCreditApps extends Component {
  componentDidMount() {
    this.props.getCreditApps();
  }

  renderContent() {
    switch (this.props.creditApps) {
      case null:
        return <p>No Credit Apps</p>;
      case false:
        return <p>No Credit Apps</p>;
      case 'Error':
        return <p>Error deleting credit app, please refresh page!</p>;
      default:
        return (
          <ul className="list-group list-group-flush">
            {this.props.creditApps.data.map(rows => (
              <li
                key={rows.idCreditApp}
                id={rows.idCreditApp}
                className="list-group-item"
              >
                {rows.FirstName} {rows.LastName}
                <div
                  className="btn-group float-right"
                  role="group"
                  aria-label="Button group with nested dropdown"
                >
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => this.downloadApp(rows.idCreditApp)}
                  >
                    Download
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => this.deleteApp(rows.idCreditApp)}
                  >
                    Delete
                  </Button>
                </div>
              </li>
            ))}
          </ul>
        );
    }
  }

  async downloadApp(clicked_id) {
    this.OpenWindowWithPost('/api/downloadCreditApp', 'creditApp', {
      creditAppID: clicked_id
    });
  }
  OpenWindowWithPost(url, name, params) {
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', name);
    for (var i in params) {
      if (params.hasOwnProperty(i)) {
        var input = document.createElement('input');
        input.type = 'hidden';
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    window.open('/api/downloadCreditApp', name);
    form.submit();
    document.body.removeChild(form);
  }

  deleteApp(clicked_id) {
    this.props.deleteCreditApp({ idCreditApp: clicked_id });
  }
  render() {
    return (
      <div className="credit_app">
        <div className="card">
          <div className="card-header">Submitted Credit Apps</div>
          {this.renderContent()}{' '}
        </div>
      </div>
    );
  }
}
function mapStateToProps({ creditApps }) {
  return { creditApps };
}
export default connect(
  mapStateToProps,
  actions
)(ViewCreditApps);
