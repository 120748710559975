import {
  DROPDOWN_VALUE_ADD_INVENTORY,
  DROPDOWN_VALUE_ADD_INVENTORY_ERROR,
} from "../actions/types";

export default function addRVInventoryReducer(state = null, action) {
  switch (action.type) {
    case DROPDOWN_VALUE_ADD_INVENTORY:
      return action.payload || false;
    case DROPDOWN_VALUE_ADD_INVENTORY_ERROR:
      return action.payload || false;
    default:
      return state;
  }
}
