import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import validate from './../validate';
import { renderField } from './../renderField';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// const renderError = ({ meta: { touched, error } }) =>
//   touched && error ? <span>{error}</span> : false;

class Specs1Form extends Component {
  componentDidMount() {
    this.props.initialize(
      {
        VIN: this.props.rvUnit[0] ? this.props.rvUnit[0].Vin : '',
        StockNumber: this.props.rvUnit[0]
          ? this.props.rvUnit[0].StockNumber
          : '',
        ShipWeight: this.props.rvUnit[0] ? this.props.rvUnit[0].ShipWeight : '',
        HitchWeight: this.props.rvUnit[0]
          ? this.props.rvUnit[0].HitchWeight
          : '',
        GrossWeight: this.props.rvUnit[0]
          ? this.props.rvUnit[0].GrossWeight
          : '',
        CargoCapacity: this.props.rvUnit[0]
          ? this.props.rvUnit[0].CargoCapacity
          : '',
        ExteriorLength: this.props.rvUnit[0]
          ? this.props.rvUnit[0].ExteriorLength
          : '',
        ExteriorHeight: this.props.rvUnit[0]
          ? this.props.rvUnit[0].ExteriorHeight
          : '',
        ExteriorWidth: this.props.rvUnit[0]
          ? this.props.rvUnit[0].ExteriorWidth
          : '',
        Slides: this.props.rvUnit[0] ? this.props.rvUnit[0].Slides : '',
        FreshWaterCapacity: this.props.rvUnit[0]
          ? this.props.rvUnit[0].FreshWaterCapacity
          : '',
        GrayWaterCapacity: this.props.rvUnit[0]
          ? this.props.rvUnit[0].GrayWaterCapacity
          : '',
        BlackWaterCapacity: this.props.rvUnit[0]
          ? this.props.rvUnit[0].BlackWaterCapacity
          : '',
        LPCapacity: this.props.rvUnit[0] ? this.props.rvUnit[0].LPCapacity : '',
        MSRP: this.props.rvUnit[0] ? this.props.rvUnit[0].MSRP : '',
        SalePrice: this.props.rvUnit[0] ? this.props.rvUnit[0].SalePrice : '',
        NewUsed: this.props.rvUnit[0] ? this.props.rvUnit[0].NewUsed : '',
        Clearance: this.props.rvUnit[0] ? this.props.rvUnit[0].Clearance : '',
        ACUnits: this.props.rvUnit[0] ? this.props.rvUnit[0].ACUnits : '',
        ACBTU: this.props.rvUnit[0] ? this.props.rvUnit[0].ACBTU : '',
        SecondACBTU: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SecondACBTU
          : '',
        FurnaceUnits: this.props.rvUnit[0]
          ? this.props.rvUnit[0].FurnaceUnits
          : '',
        FurnaceBTU: this.props.rvUnit[0] ? this.props.rvUnit[0].FurnaceBTU : '',
        SecondFurnaceBTU: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SecondFurnaceBTU
          : '',
        TireSize: this.props.rvUnit[0] ? this.props.rvUnit[0].TireSize : '',
        Axles: this.props.rvUnit[0] ? this.props.rvUnit[0].Axles : '',
        GarageLength: this.props.rvUnit[0]
          ? this.props.rvUnit[0].GarageLength
          : '',
        EngineModel: this.props.rvUnit[0]
          ? this.props.rvUnit[0].EngineModel
          : '',
        EngineFuelType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].EngineFuelType
          : '',
        Sleeps: this.props.rvUnit[0] ? this.props.rvUnit[0].Sleeps : '',
        Beds: this.props.rvUnit[0] ? this.props.rvUnit[0].Beds : '',
        PrimaryBedType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].PrimaryBedType
          : '',
        SecondBedType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SecondBedType
          : '',
        Bathrooms: this.props.rvUnit[0] ? this.props.rvUnit[0].Bathrooms : '',
        ToiletType: this.props.rvUnit[0] ? this.props.rvUnit[0].ToiletType : '',
        RefrigeratorSize: this.props.rvUnit[0]
          ? this.props.rvUnit[0].RefrigeratorSize
          : '',
        StoveBurners: this.props.rvUnit[0]
          ? this.props.rvUnit[0].StoveBurners
          : '',
        TVs: this.props.rvUnit[0] ? this.props.rvUnit[0].TVs : '',
        TVSize: this.props.rvUnit[0] ? this.props.rvUnit[0].TVSize : '',
        SecondTVSize: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SecondTVSize
          : '',
        DinetteType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].DinetteType
          : '',
        SeatingType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SeatingType
          : '',
        SecondSeatingType: this.props.rvUnit[0]
          ? this.props.rvUnit[0].SecondSeatingType
          : '',
        PrimaryAwningLength: this.props.rvUnit[0]
          ? this.props.rvUnit[0].PrimaryAwningLength
          : '',
        Description: this.props.rvUnit[0]
          ? this.props.rvUnit[0].Description
          : '',
      },
      true
    );
  }
  renderContent() {
    return this.renderForm();
  }
  renderForm() {
    return (
      <div>
        <h2>Specifications</h2>
        <form onSubmit={this.props.handleSubmit}>
          <Form.Group controlId="vin">
            <Field
              type="text"
              placeholder="Vin"
              label="vin"
              name="VIN"
              maxlength="17"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="stockNumber">
            <Field
              type="text"
              placeholder="Stock Number"
              label="stockNumber"
              name="StockNumber"
              maxlength="45"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="hitchWeight">
            <Field
              type="text"
              placeholder="Hitch Weight"
              label="hitchWeight"
              name="HitchWeight"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="shipWeight">
            <Field
              type="text"
              placeholder="Ship Weight"
              label="shipWeight"
              name="ShipWeight"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="grossWeight">
            <Field
              type="text"
              placeholder="Gross Weight (GVWR)"
              label="grossWeight"
              name="GrossWeight"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="cargoCapacity">
            <Field
              type="text"
              placeholder="Cargo Capacity"
              label="cargoCapacity"
              name="CargoCapacity"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="lengthExterior">
            <Field
              type="text"
              placeholder="Length (Exterior)"
              label="lengthExterior"
              name="ExteriorLength"
              maxlength="7"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="heightExterior">
            <Field
              type="text"
              placeholder="Height (Exterior)"
              label="heightExterior"
              name="ExteriorHeight"
              maxlength="7"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="widthExterior">
            <Field
              type="text"
              placeholder="Width (Exterior)"
              label="widthExterior"
              name="ExteriorWidth"
              maxlength="7"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="slides">
            <Field
              type="text"
              placeholder="Slides"
              label="slides"
              name="Slides"
              maxlength="3"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="freshWaterCapacity">
            <Field
              type="text"
              placeholder="Fresh Water Capacity"
              label="freshWaterCapacity"
              name="FreshWaterCapacity"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="grayWaterCapacity">
            <Field
              type="text"
              placeholder="Gray Water Capacity"
              label="grayWaterCapacity"
              name="GrayWaterCapacity"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="blackWaterCapacity">
            <Field
              type="text"
              placeholder="Black Water Capacity"
              label="blackWaterCapacity"
              name="BlackWaterCapacity"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <Form.Group controlId="lpTankCapacity">
            <Field
              type="text"
              placeholder="L.P. Tank Capacity"
              label="lpTankCapacity"
              name="LPCapacity"
              maxlength="10"
              component={renderField}
            />
          </Form.Group>
          <div>
            <Button
              className="pull-left previous"
              variant="danger"
              type="submit"
              onClick={this.props.previousPage}
            >
              Previous
            </Button>
            <Button className="pull-right next" variant="success" type="submit">
              Next
            </Button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

Specs1Form.defaultProps = {
  rvUnit: [],
};
export default reduxForm({
  form: 'wizard', //                 <------ same form name
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
})(Specs1Form);
